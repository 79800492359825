export default (function() {
	Number.prototype.toPrice = function(fixed = 0) {
		return this.toFixed(fixed)
			.replace('.', ',')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	Date.prototype.format = function(format) {
		const year = this.getFullYear();
		const month = this.getMonth();
		const day = this.getDate();
		const hours = this.getHours();
		const minutes = this.getMinutes();
		const seconds = this.getSeconds();

		return format
			.replace(/%y/g, year)
			.replace(/%-y/g, year.toString().substr(2, 2))
			.replace(/%m/g, String(month + 1).padStart(2, '0'))
			.replace(/%-m/g, String(month + 1))
			.replace(/%f/g, String(month))
			.replace(/%d/g, String(day).padStart(2, '0'))
			.replace(/%-d/g, String(day))
			.replace(/%H/g, String(hours).padStart(2, '0'))
			.replace(/%-H/g, String(hours))
			.replace(/%M/g, String(minutes).padStart(2, '0'))
			.replace(/%-M/g, String(minutes))
			.replace(/%S/g, String(seconds).padStart(2, '0'))
			.replace(/%-S/g, String(seconds));
	};
})();
