import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/stod/:id',
		name: 'Station',
		component: () => import('../views/Station.vue'),
	},
	{
		path: '/stod/:id/vorur',
		name: 'Products',
		component: () => import('../views/Products.vue'),
	},
	{
		path: '/stod/:id/tilbod/:offerId',
		name: 'Offer',
		component: () => import('../views/Offer.vue'),
	},
	{
		path: '/keypskurv',
		name: 'Cart',
		component: () => import('../views/Cart.vue'),
	},
	{
		path: '/gjald',
		name: 'Payment',
		component: () => import('../views/Payment.vue'),
	},
	{
		path: '/treytir',
		name: 'Agreement',
		component: () => import('../views/Agreement.vue'),
	},
	{
		path: '/success',
		name: 'Success',
		component: () => import('../views/Success.vue'),
	},
	{
		path: '/cancel',
		name: 'Cancel',
		component: () => import('../views/Cancel.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
